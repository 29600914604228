import React from "react";
import {
  StJohnMainContainer1,
  StjFeatureCircle,
  StJohnMainContainerSlowParalaxContainer1,
  StJohnMainContainerWithBackgImage1,
  StJohnMainContainerNoXPaddingWithBackgImage1,
  StjDisplayOnScrollContainer,
} from "../../layout/containers/containers.jsx";
import "./home.scss";
import { SmallCalendar } from "../../ui/small_calendar/small_calendar.jsx";
import { Map } from "../../ui/map/map.jsx";
import { StjLink, routes } from "../../../../site_links.jsx";
import {
  StjMainContainerFontPatuaH1,
  StjMainContainerFontPatuaH2,
  StjMainContainerFontSourceSansProH5,
} from "../../layout/headings/headings.jsx";
import {
  showNewsSection,
  showStaticHomeHeading,
} from "../../../../site_settings.js";
import { ParishBulletinSection } from "./parish_bulletin/parish_bulletin.jsx";
import { HeaderCarousel } from "./header_carousel/header_carousel.jsx";
import "./home_xmas.scss";

export const HomePage = () => (
  <div>
    {showStaticHomeHeading ? (
      <_StaticTopHeading withText={true} withOpacity={true} />
    ) : (
      <HeaderCarousel />
    )}
    <StJohnMainContainer1>
      <_WelcomeSection />
    </StJohnMainContainer1>

    <a
      href="https://www.signupgenius.com/go/10C0D4AA5AD2BA3F5C25-54893820-2025?useFullSite=true#/"
      target={"_blank"}
      style={{ textDecoration: "none" }}
    >
      <StJohnMainContainer1 backgroundColor={"#5c6b8c"}>
        <div className="position-relative row d-flex align-items-center">
          <section className="col-lg-5 d-flex align-items-center">
            <div>
              <section className="text-center" style={{ color: "#fff" }}>
                <StjMainContainerFontPatuaH2>
                  2025 Lenten Retreat with Father Sergius Bowyer
                </StjMainContainerFontPatuaH2>
              </section>
              <section
                className="fs-4 color-white"
                style={{ lineHeight: "2.4" }}
              >
                <p
                  className="text-center mt-4 fs-5 fst-italic"
                  style={{ lineHeight: "1.3" }}
                >
                  Father Sergius, Abbott of St. Tikhon Monastery, Waymart, PA,
                  will be speaking on themes presented in his book{" "}
                  <span style={{ fontWeight: "bold" }}>
                    Acquiring the Mind of Christ
                  </span>{" "}
                  which will also be available for purchase that day. We will
                  also sing the Akathist to St. Anna, the Mother of the
                  Theotokos, whose miracle-working icon will be present at the
                  retreat.
                </p>
                <p className="mt-2">
                  <div className="my-4" style={{ fontSize: "1.3rem" }}>
                    <div className="text-center fw-bold">
                      Saturday, April 5th
                      <br />
                      12:00 PM - 4:00 PM EST
                      <br />
                    </div>
                  </div>

                  <div className="mt-1 text-center" style={{ lineHeight: "1" }}>
                    Registration is $40 per person
                    <br />
                    <br />
                    <span
                      className="mt-3 fw-bold fs-2"
                      style={{ color: "#162441" }}
                    >
                      Click or Scan QR Code to Register
                    </span>
                  </div>
                </p>
              </section>
            </div>
          </section>

          <section className="mt-4 mt-lg-0 col-lg-7">
            <div className="d-flex justify-content-center">
              <figure style={{ width: "80%" }}>
                <img
                  src="./assets_2/banners/2025/lenten_retreat_2025_poster.jpg"
                  alt="2025 Lenten Retreat Flyer"
                  className="w-100 stj-shadow-1"
                />
              </figure>
            </div>
          </section>
        </div>
      </StJohnMainContainer1>
    </a>

    <StJohnMainContainerSlowParalaxContainer1
      backgroundImage={"./assets_2/images/jesus_banner.jpg"}
    >
      <div className="d-flex justify-content-center align-items-center h-100">
        <StjDisplayOnScrollContainer>
          <StjLink className="text-decoration-none" to={routes.orthodoxy}>
            <h2 className="page-heading-parallax__heading font-patua">
              Discover Ancient Christianity
            </h2>
          </StjLink>
        </StjDisplayOnScrollContainer>
      </div>
    </StJohnMainContainerSlowParalaxContainer1>
    {showNewsSection ? <ParishBulletinSection /> : null}
    <StJohnMainContainer1>
      <_FeatureCirclesSection />
    </StJohnMainContainer1>

    <StJohnMainContainer1>
      <_TimesAddressContactAndMapSection />
    </StJohnMainContainer1>
  </div>
);

const _WelcomeSection = () => (
  <section className="row">
    <div className="col-xl-6">
      <article className="stj-welcome">
        <div className="my-3 text-center">
          <StjMainContainerFontPatuaH1>Our Parish</StjMainContainerFontPatuaH1>
        </div>
        <section>
          <p>
            Founded in 1983, St. John the Theologian is an Eastern Orthodox
            parish of the OCA (Orthodox Church in America) located in Shirley,
            New York.{" "}
            <span className="fw-bold fst-italic">
              We are presently still the only English-language Orthodox Church
              serving the eastern end of Suffolk County.
            </span>
          </p>
          <br />
          <p>
            Our parish is a "pan-Orthodox" parish consisting of families from
            numerous ethnic backgrounds, truly making our community "a house of
            prayer for all people." We strive to be a community of
            compassionate, caring people united in worshipping God, in the
            English language, in the historical, unchanging truths of Orthodox
            Christianity.
          </p>
          <br />
          <p>Come join us!</p>
        </section>
      </article>
    </div>
    <div className="col-xl-6">
      <figure>
        <img
          src="assets_2/images/parish_pic_2024.jpg"
          alt="Parish Family Photo"
          className="w-100 stj-shadow-1"
        />
      </figure>
    </div>
  </section>
);

const _TimesAddressContactAndMapSection = () => (
  <section className="row align-items-center">
    <div className="col-lg-6">
      <div>
        <_TimeAndAddressAndContact />
      </div>

      <div className="mt-3">
        <Map />
      </div>
    </div>

    <div className="col-lg-6 mt-5 mt-lg-0">
      <div className="col-12 offset-md-2 col-md-8">
        <SmallCalendar />
      </div>
    </div>
  </section>
);

const _FeatureCirclesSection = () => (
  <article className="d-flex justify-content-around flex-wrap stj-feature-section">
    <div className="mb-4">
      <StjLink
        className="text-decoration-none color-black"
        to={routes.orthodoxy}
      >
        <StjDisplayOnScrollContainer>
          <StjFeatureCircle
            backgroundImage={
              "./assets_2/albums/2024/Reception of Joshua into Orthodoxy/Resized_Resized_PXL_20241013_141101695.MP.JPEG"
            }
            heading={"Orthodoxy"}
          />
        </StjDisplayOnScrollContainer>
      </StjLink>
    </div>
    <div className="mb-4">
      <StjLink className="text-decoration-none color-black" to={routes.aboutUs}>
        <StjDisplayOnScrollContainer>
          <StjFeatureCircle
            backgroundImage={
              "./assets_2/albums/2014/Phase I of the Renovation Project (10@13@14)/facade-stucco-finished.jpg"
            }
            heading={"About Us"}
          />
        </StjDisplayOnScrollContainer>
      </StjLink>
    </div>
    <div className="mb-4">
      <StjLink className="text-decoration-none color-black" to={routes.music}>
        <StjDisplayOnScrollContainer>
          <StjFeatureCircle
            backgroundImage={
              "./assets_2/albums/2024/Bishop Michael Visit (11@24@24)/PXL_20241117_145759981.MP.jpg"
            }
            heading={"Music"}
          />
        </StjDisplayOnScrollContainer>
      </StjLink>
    </div>
    <div className="mb-4">
      <StjLink className="text-decoration-none color-black" to={routes.photos}>
        <StjDisplayOnScrollContainer>
          <StjFeatureCircle
            backgroundImage={
              "./assets_2/albums/2024/Reception of Ninomiya Family into Orthodoxy/Resized_BB996CB3-7EA6-4D1E-BB56-4F0692EDDB19.JPEG"
            }
            heading={"Parish Life"}
          />
        </StjDisplayOnScrollContainer>
      </StjLink>
    </div>
  </article>
);

const _TimeAndAddressAndContact = () => (
  <section>
    <div className="text-center">
      <div>
        <StjMainContainerFontSourceSansProH5>
          Divine Liturgy
        </StjMainContainerFontSourceSansProH5>
        <p>Sunday 10:00 AM</p>
      </div>

      <div className="mt-3">
        <address>
          <StjMainContainerFontSourceSansProH5>
            Address
          </StjMainContainerFontSourceSansProH5>
          <p>158 West End Ave</p>
          <p>Shirley, NY 11967</p>
        </address>
      </div>

      <div className="mt-3">
        <StjMainContainerFontSourceSansProH5>
          Contact
        </StjMainContainerFontSourceSansProH5>
        <p>
          <a href="tel:6312815960">(631) 281-5960</a>
        </p>
        <p>
          <a href="mailto: stjohnparish@hotmail.com">
            stjohnparish@hotmail.com
          </a>
        </p>
      </div>
    </div>
  </section>
);

const _StaticTopHeadingChristmas = () => (
  <StJohnMainContainerNoXPaddingWithBackgImage1
    backgroundImage={"./assets_2/banners/2022/christmas_tree.jpg"}
    overlayOpacity={0.7}
  >
    <div className="row">
      <div
        className="col-xxl-8 d-flex align-items-center justify-content-center"
        style={{ height: "30rem", fontSize: "2rem" }}
      >
        <article className="position-relative">
          <section>
            <h1 className="white font-patua stj-banner-h1-text">
              <span className="stj-banner-h1-text__welcome">Welcome to</span>
              <br />
              <span
                style={{ textShadow: "1px 1px 0px #000000" }}
                className="text-center stj-banner-h1-text__main"
              >
                St. John the Theologian
              </span>
              <br />
              <span
                style={{
                  textShadow: "1px 1px 0px #000000",
                }}
                className="text-center stj-banner-h1-text__church"
              >
                Orthodox Church
              </span>
            </h1>
          </section>
        </article>
      </div>
      <div className="col-xxl-4 d-flex align-items-center justify-content-center text-center">
        <article className="position-relative white delayed-entrance">
          <div className="d-flex align-items-center flex-column stj-xmas-times">
            <p
              className="fs-2 fw-bold fst-italic berkshire-swash"
              style={{ color: "#bcb3ec" }}
            >
              Join Us for Nativity Services
            </p>

            <section className="my-3">
              <p className="fs-3 fst-italic fw-bold berkshire-swash">
                Christmas Eve Vigil
              </p>

              <p className="fs-4">Saturday, Dec 24th | 5:00 PM</p>
            </section>

            <section className="my-3">
              <p className="fs-3 fst-italic fw-bold berkshire-swash">
                Christmas Liturgy
              </p>

              <p className="fs-4">Sunday, Dec 25th | 10:00 AM</p>
            </section>
          </div>
        </article>
      </div>
    </div>
  </StJohnMainContainerNoXPaddingWithBackgImage1>
);

const _StaticTopHeading = ({ withText = true, withOpacity = true }) => (
  <StJohnMainContainerWithBackgImage1
    // backgroundImage={"./assets_2/images/interior_web_ready_large.jpg"}
    backgroundImage={
      "./assets_2/banners/2023/forgiveness_sunday_2023_wideshot.jpg"
    }
    overlayOpacity={withOpacity ? 0.3 : 0}
  >
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "30rem", fontSize: "2rem" }}
    >
      {withText ? (
        <section className="position-relative">
          <div>
            <h1 className="white font-patua stj-banner-h1-text">
              <span className="stj-banner-h1-text__welcome">Welcome to</span>
              <br />
              <span
                style={{ textShadow: "1px 1px 0px #000000" }}
                className="text-center stj-banner-h1-text__main"
              >
                St. John the Theologian
              </span>
              <br />
              <span
                style={{
                  textShadow: "1px 1px 0px #000000",
                }}
                className="text-center stj-banner-h1-text__church"
              >
                Orthodox Church
              </span>
            </h1>
          </div>
        </section>
      ) : null}
    </div>
  </StJohnMainContainerWithBackgImage1>
);
